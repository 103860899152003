var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center mb-4"},[_c('div',{staticStyle:{"width":"60%"}},[_c('b-input-group',[_c('b-form-input',{staticClass:"bg-background-input",attrs:{"id":"filter-input","type":"text","placeholder":"Buscar registros"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchData.apply(null, arguments)},"input":function($event){_vm.search === '' && _vm.searchData()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"text-blue-active d-flex align-items-center",attrs:{"variant":"background-input"},on:{"click":function($event){return _vm.searchData()}}},[_c('feather',{attrs:{"type":"search","size":"1.1rem"}})],1)],1)],1)],1),_c('div',{staticClass:"ml-auto"},[_c('div',{staticClass:"d-sm-flex mt-4 mt-sm-0 justify-content-end align-items-center"},[_c('input',{ref:"excelInput",staticStyle:{"display":"none"},attrs:{"type":"file","id":"excelInput","accept":".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},on:{"change":_vm.changeFile}}),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione alt+a o option+a'),expression:"'Presione alt+a o option+a'",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center mt-4 mt-sm-0 mr-4",attrs:{"variant":"primary","size":"md","disabled":!_vm.permisos.includes('subcategoria-crear')},on:{"click":_vm.addRegister}},[_c('feather',{staticClass:"mr-2 blue-active-text",attrs:{"type":"plus","size":"1rem"}}),_vm._v(" Nuevo Registro ")],1),_c('b-dropdown',{attrs:{"id":"dropdown-divider","text":"Opciones","size":"md","variant":"link","toggle-class":"text-decoration-none","no-caret":"","disabled":!_vm.permisos.includes(
                                'subcategoria-query-export'
                            ) &&
                            !_vm.permisos.includes(
                                'subcategoria-query-export-and-email'
                            ) &&
                            !_vm.permisos.includes(
                                'subcategoria-crud-export'
                            ) &&
                            !_vm.permisos.includes('subcategoria-crud-import')},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather',{staticClass:"text-secondary",attrs:{"type":"more-horizontal","size":"1.5rem"}})],1)]},proxy:true}])},[_vm._l((_vm.formats),function(format,index){return _c('b-dropdown-item-button',{key:index,attrs:{"disabled":!_vm.permisos.includes(
                                    'subcategoria-query-export'
                                )},on:{"click":function($event){return _vm.verifyFields(format.ext)}}},[_vm._v(" "+_vm._s(format.name)+" ")])}),_c('b-dropdown-item-button',{attrs:{"disabled":!_vm.permisos.includes(
                                    'subcategoria-query-export-and-email'
                                )},on:{"click":function($event){return _vm.$bvModal.show('email-modal')}}},[_vm._v("Enviar informe por Correo")]),_c('b-dropdown-divider'),_c('b-dropdown-item-button',{attrs:{"disabled":!_vm.permisos.includes(
                                    'subcategoria-crud-export'
                                )},on:{"click":function($event){return _vm.getImportFormat(_vm.moduleName)}}},[_vm._v("Plantilla para importación de tabla")]),_c('b-dropdown-divider'),_c('b-dropdown-item-button',{attrs:{"disabled":!_vm.permisos.includes(
                                    'subcategoria-crud-import'
                                )},on:{"click":function($event){return _vm.$refs.excelInput.click()}}},[_vm._v("Cargar Plantilla para importación de tabla")])],2)],1)])])]),_c('b-col',{staticClass:"d-flex align-items-stretch",attrs:{"cols":"12","lg":"12"}},[_c('b-card',{staticClass:"mb-4 w-100 h-100"},[_c('div',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}],staticClass:"table-responsive mt-3"},[_c('b-table',{staticClass:"mb-0 hover-table text-nowrap",attrs:{"fields":_vm.fields,"items":_vm.subcategorias,"tbody-tr-class":_vm.rowClass,"borderless":"","responsive":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(codigo)",fn:function(data){return [_c('span',{class:`badge bg-light-success ${
                                _vm.Theme === 'dark' && 'text-gray'
                            } badge-font-1`},[_c('feather',{staticClass:"mr-1",attrs:{"type":"hash","size":"0.8rem"}}),_vm._v(" "+_vm._s(data.value)+" ")],1)]}},{key:"cell(acciones)",fn:function(data){return [(_vm.permisos.includes('subcategoria-editar'))?_c('feather',{staticClass:"mr-2",attrs:{"type":"edit","role":"button"},on:{"click":function($event){return _vm.$router.push({
                                    name: 'Editar subcategoría',
                                    params: {
                                        subcategoria_id: data.item.id
                                    }
                                })}}}):_c('feather',{staticClass:"mr-2 text-secondary",attrs:{"type":"edit","role":"button"}}),(
                                _vm.permisos.includes('subcategoria-eliminar')
                            )?_c('feather',{attrs:{"type":"trash-2","role":"button"},on:{"click":function($event){return _vm.showConfirm(data.item)}}}):_c('feather',{staticClass:"text-secondary",attrs:{"type":"trash-2","role":"button"}}),_c('feather',{staticClass:"ml-2",attrs:{"type":"eye","role":"button"},on:{"click":function($event){return _vm.$router.push({
                                    name: 'Ver subcategoría',
                                    params: {
                                        subcategoria_id: data.item.id
                                    }
                                })}}})]}}])})],1),_c('div',{staticClass:"p-3 d-flex align-items-center"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Número de registros","label-for":"per-page-select","label-size":"sm","label-class":"fw-medium"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pagination.pageOptions,"size":"sm"},on:{"change":function($event){return _vm.getSubcategorias('', 1, $event)}},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}})],1),_c('div',{staticClass:"ml-auto"},[_c('b-pagination',{staticClass:"my-0",attrs:{"first-number":"","last-number":"","size":"sm","total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"align":"fill"},on:{"input":function($event){return _vm.getSubcategorias('', $event, _vm.pagination.perPage)}},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1)]),_c('selectFieldsReport',{attrs:{"modulo":_vm.moduleName,"format":_vm.selectedFormat,"fields":_vm.fieldsAvailables[
                    _vm.selectedFormat ? _vm.selectedFormat.toLowerCase() : 'excel'
                ]},on:{"close":function($event){_vm.selectedFormat = null}}}),_c('emailModal',{attrs:{"sender":_vm.configEmail,"modulo":_vm.firstToUpperString(_vm.moduleName),"fieldsAvailables":_vm.fieldsAvailables}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-sm-flex mt-4 justify-content-between align-items-center"},[_c('input',{ref:"excelInput",staticStyle:{"display":"none"},attrs:{"type":"file","id":"excelInput","accept":".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},on:{"change":_vm.changeFile}}),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione alt+a o option+a'),expression:"'Presione alt+a o option+a'",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center mt-4 mt-sm-0 mr-4",attrs:{"variant":"primary","size":"md","disabled":!_vm.permisos.includes('subcategoria-crear')},on:{"click":_vm.addRegister}},[_c('feather',{staticClass:"mr-2 blue-active-text",attrs:{"type":"plus","size":"1rem"}}),_vm._v(" Nuevo Registro ")],1),_c('b-dropdown',{attrs:{"id":"dropdown-divider","text":"Opciones","size":"md","variant":"link","toggle-class":"text-decoration-none","no-caret":"","disabled":!_vm.permisos.includes('subcategoria-query-export') &&
                    !_vm.permisos.includes(
                        'subcategoria-query-export-and-email'
                    ) &&
                    !_vm.permisos.includes('subcategoria-crud-export') &&
                    !_vm.permisos.includes('subcategoria-crud-import')},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather',{staticClass:"text-secondary",attrs:{"type":"more-horizontal","size":"1.5rem"}})],1)]},proxy:true}])},[_vm._l((_vm.formats),function(format,index){return _c('b-dropdown-item-button',{key:index,attrs:{"disabled":!_vm.permisos.includes('subcategoria-query-export')},on:{"click":function($event){return _vm.verifyFields(format.ext)}}},[_vm._v(" "+_vm._s(format.name)+" ")])}),_c('b-dropdown-item-button',{attrs:{"disabled":!_vm.permisos.includes(
                            'subcategoria-query-export-and-email'
                        )},on:{"click":function($event){return _vm.$bvModal.show('email-modal')}}},[_vm._v("Enviar informe por Correo")]),_c('b-dropdown-divider'),_c('b-dropdown-item-button',{attrs:{"disabled":!_vm.permisos.includes('subcategoria-crud-export')},on:{"click":function($event){return _vm.getImportFormat(_vm.moduleName)}}},[_vm._v("Plantilla para importación de tabla")]),_c('b-dropdown-divider'),_c('b-dropdown-item-button',{attrs:{"disabled":!_vm.permisos.includes('subcategoria-crud-import')},on:{"click":function($event){return _vm.$refs.excelInput.click()}}},[_vm._v("Cargar Plantilla para importación de tabla")])],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }